import React, { useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from '../../../hooks/useWindowSize'

const StreamContainer = ({ stream, showMenu }) => {
  // hooks
  const location = useLocation()
  const aspectRatioRef = useRef()
  // redux
  const { videoUrlEmbed, videoUrlHls } = useSelector(state => state.globalValueReducer.urlFromVideo)

  // state
  const [height, setHeight] = useState(0)
  const [orientation, setOrientation] = useState(window?.orientation)
  const [openModal, setOpenModal] = useState(false)

  const windowSize = useWindowSize()

  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      if (window.outerHeight > window.outerWidth) {
        return setTimeout(() => {
          setOrientation(90)
          setOpenModal(true)
        })
      } else {
        return setTimeout(() => {
          setOrientation(0)
          setOpenModal(false)
        })
      }
    })
  }, [])

  window.addEventListener('resize', () => {
    setTimeout(() => {
      setHeight(aspectRatioRef?.current?.clientWidth / (320 / 103))
    }, 1000)
  })

  useEffect(() => {
    setHeight(aspectRatioRef?.current?.clientWidth)
  }, [showMenu, windowSize])

  return (
        <>
            {
                (!location.pathname.includes('video') || videoUrlEmbed === 'false' && videoUrlHls === 'false') ? stream?.items[0]?.status === 'PUBLISHED'

                  ? stream?.items[0].embed_code
                    ? <div ref={aspectRatioRef}
                                   className={`${orientation === 90 && openModal ? 'full-screen stream-embed' : 'responsive-iframe stream-embed'}`}>
                                {parse(
                                    `<div style="width: 100%; height: ${aspectRatioRef?.current?.clientHeight - (windowSize.width > 1400 ? 72 : 80) + 'px'};   " ><iframe height="100%" width="100%" className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${stream?.items[0].embed_code}  mozallowfullscreen webkitallowfullscreen allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true" allow="autoplay"></iframe></div>`
                                )}
                            </div>

                    : <div ref={aspectRatioRef}
                                   className={` ${orientation === 90 && openModal ? 'full-screen stream-embed-hls' : 'responsive-iframe stream-embed-hls'}`}>
                                <ReactPlayer
                                    style={{ aspectRatio: height ? 0 : '320 / 103' }}
                                    url={stream?.items[0]?.hls_url}
                                    controls={true}
                                    width="100%"
                                    height={window.outerWidth < 1024 ? height : '100%'}
                                />
                            </div>

                  : <div className="responsive-iframe" style={{ backgroundColor: '#f3f4f8' }}>

                            {parse(
                                `<div style="width: 100%; " >
                                        <iframe src="https://player.castr.com/vod/4CQG6KGbbjAx8AXp" width="100%" style="aspect-ratio: 16/9; min-height: 340px;" frameborder="0" scrolling="no" allow="autoplay" mozallowfullscreen webkitallowfullscreen allowfullscreen allow="autoplay"></iframe>
                                    </div>`
                            )}
                            {/* <img src={Bottom_Banner} */}
                            {/*                         className="banner-img" */}
                            {/*                         width="100%" */}
                            {/*                         // height="100%" */}
                            {/*                         alt="event-card"/> */}
                            <p style={{
                              marginLeft: '10px',
                              marginTop: '10px',
                              fontSize: '46px',
                              color: 'black',
                              fontStyle: 'bold'
                            }}>&nbsp;
                            </p>
                        </div>

                  : videoUrlEmbed

                    ? <div ref={aspectRatioRef}
                               className={` ${orientation === 90 && openModal ? 'full-screen video-embed' : 'responsive-iframe video-embed'}`}>
                            {parse(
                                `<div style="width: 100%; height: ${aspectRatioRef?.current?.clientHeight - (windowSize.width > 1400 ? 72 : 80) + 'px'};   " ><iframe height="100%" width="100%" className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${videoUrlEmbed}  mozallowfullscreen webkitallowfullscreen allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true" allow="autoplay"></iframe></div>`
                            )}
                            {/* {parse( */}
                            {/*    `<div style="width: 100%; height: ${height}px; aspect-ratio: ${height ? 0 : '320 / 103'};   " ><iframe height="100%"  width="100%" className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${videoUrlEmbed} allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe></div>` */}
                            {/* )} */}
                        </div>

                    : <div ref={aspectRatioRef}
                               className={` ${orientation === 90 && openModal ? 'full-screen video-embed-hls' : 'responsive-iframe video-embed-hls'}`}>
                            <ReactPlayer
                                style={{ aspectRatio: '320 / 103', width: '100%' }}
                                url={videoUrlHls}
                                controls={true}
                                width="100%"
                                height={height}
                            />
                        </div>
            }
        </>
  )
}

export default StreamContainer

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Chats from './Chats/Chats'
import PublicChat from './Chats/PublicChat/PublicChat'
import { useDispatch } from 'react-redux'
import { Tab, Tabs } from '@mui/material'
// src
import './InboxPage.scss'
import { connectToSB, ConnectUserToSendBird } from './SendBird/action'
// import ChatIcon from '../../SVG/global/ChatIcon'
import colors from '../../Colors.scss'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import { useGetAttendeeProfileQuery } from '../../redux/api/authApi/authApi'
import { useGroupChannelHandler } from '../../hooks/sendBird/useChannelHandler'
import { useAuthValue, useGlobalValue, useSendBirdValue } from '../../hooks/redux/reduxHooks'
import { getAllCountChannel } from '../../helper/sendBird/getAllCountChannel'
import { addUnreadMsgCount } from '../../redux/slices/SendBirdSlice/SendBirdSlice'
import { useGetUserAccessQuery } from '../../redux/api/userApi/userApi'
// import QuestionIcon from '../../SVG/global/QuestionIcon'

// custom SFS
import ChatIcon from '../../SVG/custom/ChatIcon'
import QuestionsIcon from '../../SVG/custom/QuestionsIcon'

export default function InboxPage () {
  // hooks
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { channelHandler, refreshMessage } = useGroupChannelHandler()

  const currentTab = localStorage.getItem('selectedTab')

  // state
  const [selectedTab, setSelectedTab] = useState(currentTab || 'pub-chat')
  const [showMenu] = useState(true)
  const [connectedUserToSendBird, setConnectedUserToSendBird] = useState(false)
  const [userMsg, setUserMsg] = useState()
  const [sendBird, setSendBird] = useState()
  const [connecting, setConnecting] = useState(false)
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  // redux
  const { userId } = useAuthValue()
  const { userSendBird } = useSendBirdValue()
  const { eventId } = useGlobalValue()

  // api
  const { data: profileDetails } = useGetAttendeeProfileQuery(userId)
  const { data: userAccess } = useGetUserAccessQuery(eventId)

  const chat_enabled = userAccess?.event_features?.chat_enabled
  const hotline_enabled = userAccess?.event_features?.hotline_enabled

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
    localStorage.setItem('selectedTab', newValue)
  }

  useEffect(() => {
    if (!(chat_enabled || hotline_enabled)) return
    connectToSB().then(value => setSendBird(value)).catch(e => console.error('Error connecting to SendBird:', e))
  }, [userAccess])

  useEffect(() => {
    if (!(chat_enabled || hotline_enabled)) return
    if (!connecting && !connectedUserToSendBird && profileDetails && !!sendBird) {
      setConnecting(false)
      ConnectUserToSendBird(profileDetails, sendBird).then(value => {
        setConnectedUserToSendBird(value)
      }).finally(() => setConnecting(true))
    }
    if (connecting) {
      sendBird?.groupChannel?.addGroupChannelHandler(profileDetails?.userId + '', channelHandler)
    }
  }, [profileDetails, sendBird, connectedUserToSendBird, userAccess])

  useEffect(() => {
    (async () => {
      if (!(chat_enabled || hotline_enabled)) return
      const { allChannels, countUnreadMsg } = await getAllCountChannel({ sendBird, userSendBird, dispatch }) || {}
      setUserMsg(allChannels)
      dispatch(addUnreadMsgCount(+countUnreadMsg))
    })()
  }, [sendBird, refreshMessage, userSendBird, userAccess])

  return (

        <>
            {/* <ToastContainer /> */}

            <div className="inbox-main">
                {showMenu && (
                    <div className="conversation-main">
                        <div className="title-main">
                            <div className="title">
                                <ChatIcon color={colors.color_sidebar_icon} width='30' height='30'/>
                                <p>
                                  <span className="help_span"
                                        onClick={(e) => {
                                          setShowModalHelper({
                                            open: !showModalHelper.open,
                                            event: e.currentTarget
                                          })
                                        }}>
                                    {t('pages.inboxpage.chats.conversations')}
                                      {showModalHelper && <ContextHelp helpText={{
                                        id: 3,
                                        what: t('pages.inboxpage.chats.context-help-public-private-chat')
                                      }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                                       setShowModalHelper={setShowModalHelper}/>}
                                  </span>
                                </p>
                            </div>
                        </div>
                        <div className="chat-tabs">
                            <Tabs
                                TabIndicatorProps={{ style: { display: 'none' } }}
                                onChange={handleChangeTab}
                                textColor="inherit"
                                indicatorColor="primary"
                                value={`${selectedTab}`}
                                sx={{
                                  width: '100%',
                                  '& .MuiTab-root': {
                                    color: 'black',
                                    backgroundColor: '#f5f5f5'
                                  },
                                  '& .Mui-selected': {
                                    color: 'white',
                                    backgroundColor: 'black'
                                  }
                                }}
                            >
                                <div className='inbox-helper-mobile'
                                     onClick={(e) => {
                                       setShowModalHelper({
                                         open: !showModalHelper.open,
                                         event: e.currentTarget
                                       })
                                     }}>
                                    <QuestionsIcon style={{ lineHeight: 50 }} color={colors.color_sidebar_icon} width='20'
                                                   height='20'/>
                                    {showModalHelper && <ContextHelp helpText={{
                                      id: 3,
                                      what: t('pages.inboxpage.chats.context-help-public-private-chat')
                                    }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                                     setShowModalHelper={setShowModalHelper}/>}
                                </div>

                                <Tab value="pub-chat"
                                     className="pub-chat"
                                     label={t('pages.inboxpage.chats.public-chat')}
                                     sx={{
                                       width: '50%',
                                       maxWidth: '50%'

                                     }}
                                />
                                <Tab className='pr-chat'
                                     value="pr-chat"
                                     label={t('pages.inboxpage.chats.private-chat')}
                                     sx={{
                                       width: '50%',
                                       maxWidth: '50%'
                                     }}/>
                            </Tabs>
                        </div>
                        {chat_enabled
                          ? selectedTab === 'pr-chat'
                            ? <Chats sendBird={sendBird} userMsg={userMsg} user={profileDetails}
                                         connecting={connecting}/>
                            : <PublicChat sb={sendBird} userInbox={connectedUserToSendBird}/>
                          : <></>
                        }
                    </div>
                )}
                {/* <SideBar onChange={handleChange} selectedprop={"inbox"} /> */}
            </div>

        </>
  )
}
